body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;

  background: #013492;

  font-family: "Roboto Slab", serif;
}
.App {
  padding-top: 16px;
  background: #013492;
}
html {
  background: #eceff4;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 44px;
  font-weight: 700;
  color: #2a333c;
}
h1 span {
  font-size: 24px;
  color: #d5586a;
  line-height: 36px;
  display: inline-block;
  padding-top: 16px;
}

.container {
  max-width: 1000px;
}

span.code,
h1 span.code {
  display: block;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  opacity: 0.6;
}
span.code-component {
  display: inline-block;
  font-weight: 800;
  color: #d5586a;
}
span.code-prop {
  color: #43869c;
}
